import React from "react";
import { useState } from "react";
import FoodDataService from "../services/foods.services";
import { Form, Button, Row, Col } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { db } from "../firebase";

import { query, collection, getDocs, where } from "firebase/firestore";
import AddFoodComponent from "./AddFoodComponent";
import AskoToFoodWin from "./AskoToFoodWin";

const AddFoodFromAsko = () => {
    const [brand, setBrand] = useState("");
    const [food, setFood] = useState("");
    const [amount, setAmount] = useState(0);
    const [unitOfMeasurement, setUnitOfMeasurement] = useState("stk");
    const [category, setCategory] = useState(1);
    const [countryOfOrigin, setCountryOfOrigin] = useState("");
    const [gtin, setGtin] = useState(0);

    const [displayNutrients, setDisplayNutrients] = useState(false);

    const [energy, setEnergy] = useState(0);
    const [calories, setCalories] = useState(0);
    const [fat, setFat] = useState(0);
    const [saturatedFat, setSaturatedFat] = useState(0);
    const [carbohydrates, setCarbohydrates] = useState(0);
    const [sugars, setSugars] = useState(0);
    const [dietaryFiber, setDietaryFiber] = useState(0);
    const [protein, setProtein] = useState(0);
    const [salt, setSalt] = useState(0);

    const [ingredients, setIngredients] = useState([]);
    const [allergens, setAllergens] = useState([]);
    const [chains, setChains] = useState([]);

    const [errorMessages, setErrorMessages] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);

    const handleRemoveIngredient = (e) => {
        const name = e.target.getAttribute("name");
        setIngredients(ingredients.filter((ingredient) => ingredient !== name));
    };

    const handleRemoveAllergen = (e) => {
        const name = e.target.getAttribute("name");
        setAllergens(allergens.filter((allergen) => allergen !== name));
    };

    const handleRemoveChain = (e) => {
        const name = e.target.getAttribute("name");
        setChains(chains.filter((chain) => chain !== name));
    };

    const foodIsNew = async () => {
        const q = query(collection(db, "foods"), where("gtin", "==", gtin));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newFood = {
            brand,
            food,
            amount,
            unitOfMeasurement,
            category,
            countryOfOrigin,
            gtin,
            nutrients: {
                energy,
                calories,
                fat,
                saturatedFat,
                carbohydrates,
                sugars,
                dietaryFiber,
                protein,
                salt,
            },
            ingredients,
            allergens,
            chains,
        };

        if (
            brand &&
            food &&
            unitOfMeasurement &&
            category &&
            countryOfOrigin &&
            gtin
        ) {
            if (await foodIsNew()) {
                try {
                    if (
                        window.confirm(
                            "Er du sikker på at du vil legge til: " +
                            food +
                            " fra " +
                            brand +
                            "?"
                        )
                    ) {
                        await FoodDataService.addFoods(newFood);
                        setSuccessMessages((successMessages) => [
                            ...successMessages,
                            `${food} ble lagt til i databasen!`,
                        ]);
                        document.getElementById("searchGtin").value = "";
                        setGtin(0);
                        setFood("");
                        setBrand("");
                        setCountryOfOrigin("");
                        setAllergens([]);
                        setErrorMessages([]);
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                setErrorMessages((errorMessages) => [
                    ...errorMessages,
                    "Matvaren er allerede lagt til i databasen",
                ]);
            }
        } else {
            setErrorMessages((errorMessages) => [
                ...errorMessages,
                "Noen felter inneholder feil",
            ]);
        }
    };

    const getFoodFromNgData = async (e) => {
        e.preventDefault();
        setSuccessMessages([]);
        setErrorMessages([]);
        if ((await foodIsNew()) == false) {
            setErrorMessages((errorMessages) => [
                ...errorMessages,
                "Matvaren er allerede lagt til i databasen",
            ]);
        } else {
            let response = await fetch(
                `https://api.ngdata.no/sylinder/search/productsearch/v1/search/7080001150488/products?search=${gtin}&chainId=1300`
            );
            let data = await response.json();
            try {
                if (typeof data.hits == "object") {
                    try {
                        let hit = data.hits[0];
                        setFood(hit.title.toLowerCase());

                        try {
                            setCountryOfOrigin(
                                hit.countryOfOrigin.toLowerCase()
                            );
                        } catch (e) {
                            setErrorMessages((errorMessages) => [
                                ...errorMessages,
                                "Opprinnelsesland ble ikke funnet hos Meny",
                            ]);
                        }

                        try {
                            setBrand(hit.brand.toLowerCase());
                        } catch (e) {
                            setErrorMessages((errorMessages) => [
                                ...errorMessages,
                                "Merke ble ikke funnet hos Meny",
                            ]);
                        }

                        try {
                            for (const index in data.facets) {
                                const facet = data.facets[index];
                                if (
                                    facet.name == "Allergens" ||
                                    facet.name == "allergens"
                                ) {
                                    console.log(facet);
                                    if (
                                        parseInt(facet.facetValues.lupiner) == 1
                                    ) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "lupin",
                                        ]);
                                    }
                                    if (facet.facetValues.selleri == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "celery",
                                        ]);
                                    }
                                    if (
                                        parseInt(facet.facetValues.sennep) == 1
                                    ) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "mustard",
                                        ]);
                                    }
                                    if (facet.facetValues.gluten == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "gluten",
                                        ]);
                                    }
                                    if (facet.facetValues.fisk == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "fish",
                                        ]);
                                    }
                                    if (facet.facetValues.soya == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "soy",
                                        ]);
                                    }
                                    if (facet.facetValues.sesam == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "sesame",
                                        ]);
                                    }
                                    if (facet.facetValues.sulfitt == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "sulphites",
                                        ]);
                                    }
                                    if (facet.facetValues.notter == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "treeNuts",
                                        ]);
                                    }
                                    if (facet.facetValues.peanotter == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "peanuts",
                                        ]);
                                    }
                                    if (facet.facetValues.egg == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "eggs",
                                        ]);
                                    }
                                    if (facet.facetValues.blotdyr == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "molluscs",
                                        ]);
                                    }
                                    if (facet.facetValues.skalldyr == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "shellfish",
                                        ]);
                                    }
                                    if (facet.facetValues.melk == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "milk",
                                        ]);
                                    }
                                }
                            }
                        } catch (e) {
                            setErrorMessages((errorMessages) => [
                                ...errorMessages,
                                "Matvarens allergener ble ikke funnet",
                            ]);
                        }
                    } catch (e) {
                        setErrorMessages((errorMessages) => [
                            ...errorMessages,
                            "Matvaren ble ikke funnet hos Meny",
                        ]);
                    }
                }
            } catch (e) {
                setErrorMessages((errorMessages) => [
                    ...errorMessages,
                    "Matvaren ble ikke funnet hos Meny",
                ]);
            }
        }
    };

    return (
        <>
            <Link to="/home">
                <Button
                    className="position-absolute start-0 top-0 m-3"
                    variant="light"
                >
                    &lt;
                </Button>
            </Link>
            <Row>
                <Col>
                    <AskoToFoodWin
                        addFood={(food) => {
                            console.log(food)
                            setBrand(food.brand)
                            setFood(food.food)
                            setGtin(food.gtin)
                            setCountryOfOrigin(food.countryOfOrigin)
                            setAmount(food.amount)
                            setUnitOfMeasurement(food.unitOfMeasurement)
                            console.log(brand)
                        }}
                    />
                </Col>
                <Col>
                    <AddFoodComponent
                        food={{
                            brand: brand.toLowerCase(),
                            food: food.toLowerCase(),
                            gtin: gtin,
                            countryOfOrigin: countryOfOrigin.toLowerCase(),
                            amount: Number(amount.toString().replace(",", ".")),
                            unitOfMeasurement: unitOfMeasurement.toLowerCase(),
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default AddFoodFromAsko;
