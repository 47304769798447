import React, { useState } from "react";
import FoodListAddImage from "./FoodListAddImage";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";

const AddImage = () => {
    const [foodSearch, setFoodSearch] = useState("");
    const [gtinSearch, setGtinSearch] = useState("");
    const [foodSearchValue, setFoodSearchValue] = useState("");
    const [gtinSearchValue, setGtinSearchValue] = useState("");
    const [searchFor, setSearchFor] = useState("gtin");

    return (
        <div className="p-4 box text-center overflow-auto">
            <img
                src="https://firebasestorage.googleapis.com/v0/b/askvikdevelopmentwebsite.appspot.com/o/foodwinlogo512.png?alt=media&token=58a4b6d6-41f8-4dda-a8f5-36f454e3097d"
                alt="Logo"
                id="logo"
            />
            <h2 className="mb-3">Matvaresøk</h2>
            {searchFor == "food" && (
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setFoodSearch(foodSearchValue);
                    }}
                >
                    <Form.Group className="mb-3">
                        <Form.Select
                            className="mb-3"
                            onChange={(e) => setSearchFor(e.target.value)}
                        >
                            <option selected value="food">
                                Matvare
                            </option>
                            <option value="gtin">GTIN</option>
                        </Form.Select>
                        <Form.Control
                            type="text"
                            placeholder="Verdi"
                            className="mb-3"
                            onChange={(e) => {
                                setFoodSearchValue(e.target.value);
                            }}
                        />
                        <Button
                            className="w-100"
                            type="submit"
                            variant="primary"
                        >
                            Søk
                        </Button>
                    </Form.Group>
                </Form>
            )}

            {searchFor == "gtin" && (
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        setGtinSearch(gtinSearchValue);
                    }}
                >
                    <Form.Group className="mb-3">
                        <Form.Select
                            className="mb-3"
                            onChange={(e) => setSearchFor(e.target.value)}
                        >
                            <option value="food">Matvare</option>
                            <option selected value="gtin">
                                GTIN
                            </option>
                        </Form.Select>
                        <Form.Control
                            type="text"
                            placeholder="Verdi"
                            className="mb-3"
                            onChange={(e) => {
                                setGtinSearchValue(e.target.value);
                            }}
                        />
                        <Button
                            className="w-100"
                            type="submit"
                            variant="primary"
                        >
                            Søk
                        </Button>
                    </Form.Group>
                </Form>
            )}

            <FoodListAddImage
                foodSearchValue={foodSearch}
                gtinSearchValue={gtinSearch}
            />
        </div>
    );
};

export default AddImage;
