import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { db } from "../firebase";

import { query, collection, getDocs, where, limit } from "firebase/firestore";

const NewFoodList = (props) => {
    const [foods, setFoods] = useState([]);
    const [searchStatus, setSearchStatus] = useState("Laster...");

    useEffect(() => {
        setFoods([]);
        getFood("food", ">=", props.foodSearchValue);
    }, [props.foodSearchValue, props.limit]);

    useEffect(() => {
        setFoods([]);
        getFood("gtin", "==", parseInt(props.gtinSearchValue));
    }, [props.gtinSearchValue]);

    const getFood = async (value, operator, searchValue) => {
        const q = query(
            collection(db, "newFoods"),
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id);
            console.log(doc.data());
            setFoods((foods) => [...foods, doc.data()]);
            if (foods.length !== 0) {
                setSearchStatus("Laster...");
            } else {
                setSearchStatus("Ingen resultater");
            }
        });
    };

    return (
        <>
            {foods.length > 0 && (
                <>
                    {foods.map((doc, index) => {
                        return (
                            <div className="foodItemContainer">
                                <p className="textInList boldText">
                                    {doc.gtin}
                                </p>
                                <p className="textInList">
                                    {doc.description}
                                </p>
                            </div>
                        );
                    })}
                </>
            )}
            {foods.length === 0 && <p>{searchStatus}</p>}
        </>
    );
};

export default NewFoodList;
