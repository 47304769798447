import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { db } from "../firebase";

import { query, collection, getDocs, where, limit } from "firebase/firestore";

const FoodList = (props) => {
    const [foods, setFoods] = useState([]);
    const [searchStatus, setSearchStatus] = useState("Laster...");

    useEffect(() => {
        setFoods([]);
        getFood("food", ">=", props.foodSearchValue);
    }, [props.foodSearchValue, props.limit]);

    useEffect(() => {
        setFoods([]);
        getFood("gtin", "==", parseInt(props.gtinSearchValue));
    }, [props.gtinSearchValue]);

    const getFood = async (value, operator, searchValue) => {
        const q = query(
            collection(db, "foods"),
            where(value, operator, searchValue),
            limit(props.limit)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id);
            console.log(doc.data());
            setFoods((foods) => [...foods, doc.data()]);
            if (foods.length !== 0) {
                setSearchStatus("Laster...");
            } else {
                setSearchStatus("Ingen resultater");
            }
        });
    };

    const getImage = (index) => {
        if (foods[index].hasOwnProperty("image")) {
            return foods[index].image;
        } else {
            return "https://firebasestorage.googleapis.com/v0/b/askvikdevelopmentwebsite.appspot.com/o/foodwinlogo512.png?alt=media&token=58a4b6d6-41f8-4dda-a8f5-36f454e3097d";
        }
    };

    return (
        <>
            {foods.length > 0 && (
                <>
                    {foods.map((doc, index) => {
                        return (
                            <div className="foodItemContainer">
                                <p className="textInList boldText">
                                    {doc.food.charAt(0).toUpperCase() +
                                        doc.food.slice(1)}
                                </p>
                                <p className="textInList">
                                    {doc.amount} {doc.unitOfMeasurement},{" "}
                                    {doc.brand.charAt(0).toUpperCase() +
                                        doc.brand.slice(1)}
                                </p>
                                <p className="textInList">GTIN: {doc.gtin}</p>
                                <img
                                    className="logoInList"
                                    src={getImage(index)}
                                />
                            </div>
                        );
                    })}
                </>
            )}
            {foods.length === 0 && <p>{searchStatus}</p>}
        </>
    );
};

export default FoodList;
