import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";

import { db } from "../firebase";

import {
    query,
    collection,
    getDocs,
    where,
    limit,
    doc,
    setDoc,
} from "firebase/firestore";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";

const FoodListAddImage = (props) => {
    const [foods, setFoods] = useState([]);
    const [searchStatus, setSearchStatus] = useState("Laster...");
    const [file, setFile] = useState("");
    const [docId, setDocId] = useState("");

    const [percent, setPercent] = useState(0);

    const storage = getStorage();

    useEffect(() => {
        setFoods([]);
        getFood("food", props.foodSearchValue);
    }, [props.foodSearchValue]);

    useEffect(() => {
        setFoods([]);
        getFood("gtin", parseInt(props.gtinSearchValue));
    }, [props.gtinSearchValue]);

    const getFood = async (value, searchValue) => {
        const q = query(
            collection(db, "foods"),
            where(value, ">=", searchValue),
            limit(1)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setDocId(doc.id);
            console.log(doc.id);
            console.log(doc.data());
            setFoods((foods) => [...foods, doc.data()]);
            if (foods.length !== 0) {
                setSearchStatus("Laster...");
            } else {
                setSearchStatus("Ingen resultater");
            }
        });
    };

    const getImage = (index) => {
        if (foods[index].hasOwnProperty("image")) {
            return foods[index].image;
        } else {
            return "https://firebasestorage.googleapis.com/v0/b/askvikdevelopmentwebsite.appspot.com/o/foodwinlogo512.png?alt=media&token=58a4b6d6-41f8-4dda-a8f5-36f454e3097d";
        }
    };

    const handleUpload = () => {
        if (!file) {
            alert("Velg en fil først");
        }

        const storageRef = ref(storage, `/foods/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                ); // update progress
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log(url);
                    document.getElementById("image").src = url;

                    const foodRef = doc(db, "foods", docId);
                    setDoc(foodRef, { image: url }, { merge: true });
                });
            }
        );
    };

    function handleFileChange(event) {
        setFile(event.target.files[0]);
    }
    return (
        <>
            {foods.length > 0 && (
                <>
                    {foods.map((doc, index) => {
                        return (
                            <div className="foodItemContainerImage">
                                <p className="textInList boldText">
                                    {doc.food.charAt(0).toUpperCase() +
                                        doc.food.slice(1)}
                                </p>
                                <p className="textInList">
                                    {doc.amount} {doc.unitOfMeasurement},{" "}
                                    {doc.brand.charAt(0).toUpperCase() +
                                        doc.brand.slice(1)}
                                </p>
                                <p className="textInList">GTIN: {doc.gtin}</p>
                                <img
                                    className="logoInList"
                                    id={"image"}
                                    src={getImage(index)}
                                />
                                <input
                                    type="file"
                                    className="uploadImage"
                                    id={"item" + index}
                                    onChange={handleFileChange}
                                ></input>
                                <Button
                                    className="w-100 margin4"
                                    variant="success"
                                    type="submit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleUpload();
                                    }}
                                >
                                    Last opp bilde til Firestore
                                </Button>
                                <p>{percent}% fullført</p>
                            </div>
                        );
                    })}
                </>
            )}
            {foods.length === 0 && <p>{searchStatus}</p>}
        </>
    );
};

export default FoodListAddImage;
