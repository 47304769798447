import React from "react";
import { useState } from "react";
import FoodDataService from "../services/foods.services";
import { Form, Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { db } from "../firebase";

import { query, collection, getDocs, where } from "firebase/firestore";

const AddFood = () => {
    const [brand, setBrand] = useState("");
    const [food, setFood] = useState("");
    const [amount, setAmount] = useState(0);
    const [unitOfMeasurement, setUnitOfMeasurement] = useState("stk");
    const [category, setCategory] = useState(1);
    const [countryOfOrigin, setCountryOfOrigin] = useState("");
    const [gtin, setGtin] = useState(0);

    const [displayNutrients, setDisplayNutrients] = useState(false);

    const [energy, setEnergy] = useState(0);
    const [calories, setCalories] = useState(0);
    const [fat, setFat] = useState(0);
    const [saturatedFat, setSaturatedFat] = useState(0);
    const [carbohydrates, setCarbohydrates] = useState(0);
    const [sugars, setSugars] = useState(0);
    const [dietaryFiber, setDietaryFiber] = useState(0);
    const [protein, setProtein] = useState(0);
    const [salt, setSalt] = useState(0);

    const [ingredients, setIngredients] = useState([]);
    const [allergens, setAllergens] = useState([]);
    const [chains, setChains] = useState([]);

    const [errorMessages, setErrorMessages] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);

    const handleRemoveIngredient = (e) => {
        const name = e.target.getAttribute("name");
        setIngredients(ingredients.filter((ingredient) => ingredient !== name));
    };

    const handleRemoveAllergen = (e) => {
        const name = e.target.getAttribute("name");
        setAllergens(allergens.filter((allergen) => allergen !== name));
    };

    const handleRemoveChain = (e) => {
        const name = e.target.getAttribute("name");
        setChains(chains.filter((chain) => chain !== name));
    };

    const foodIsNew = async () => {
        const q = query(collection(db, "foods"), where("gtin", "==", gtin));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            return true;
        } else {
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newFood = {
            brand,
            food,
            amount,
            unitOfMeasurement,
            category,
            countryOfOrigin,
            gtin,
            nutrients: {
                energy,
                calories,
                fat,
                saturatedFat,
                carbohydrates,
                sugars,
                dietaryFiber,
                protein,
                salt,
            },
            ingredients,
            allergens,
            chains,
        };

        if (
            brand &&
            food &&
            unitOfMeasurement &&
            category &&
            countryOfOrigin &&
            gtin
        ) {
            if (await foodIsNew()) {
                try {
                    if (
                        window.confirm(
                            "Er du sikker på at du vil legge til: " +
                                food +
                                " fra " +
                                brand +
                                "?"
                        )
                    ) {
                        await FoodDataService.addFoods(newFood);
                        setSuccessMessages((successMessages) => [
                            ...successMessages,
                            `${food} ble lagt til i databasen!`,
                        ]);
                        document.getElementById("searchGtin").value = "";
                        setGtin(0);
                        setFood("");
                        setBrand("");
                        setCountryOfOrigin("");
                        setAllergens([]);
                        setErrorMessages([]);
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                setErrorMessages((errorMessages) => [
                    ...errorMessages,
                    "Matvaren er allerede lagt til i databasen",
                ]);
            }
        } else {
            setErrorMessages((errorMessages) => [
                ...errorMessages,
                "Noen felter inneholder feil",
            ]);
        }
    };

    const getFoodFromNgData = async (e) => {
        e.preventDefault();
        setSuccessMessages([]);
        setErrorMessages([]);
        if ((await foodIsNew()) == false) {
            setErrorMessages((errorMessages) => [
                ...errorMessages,
                "Matvaren er allerede lagt til i databasen",
            ]);
        } else {
            let response = await fetch(
                `https://api.ngdata.no/sylinder/search/productsearch/v1/search/7080001150488/products?search=${gtin}&chainId=1300`
            );
            let data = await response.json();
            try {
                if (typeof data.hits == "object") {
                    try {
                        let hit = data.hits[0];
                        setFood(hit.title.toLowerCase());

                        try {
                            setCountryOfOrigin(
                                hit.countryOfOrigin.toLowerCase()
                            );
                        } catch (e) {
                            setErrorMessages((errorMessages) => [
                                ...errorMessages,
                                "Opprinnelsesland ble ikke funnet hos Meny",
                            ]);
                        }

                        try {
                            setBrand(hit.brand.toLowerCase());
                        } catch (e) {
                            setErrorMessages((errorMessages) => [
                                ...errorMessages,
                                "Merke ble ikke funnet hos Meny",
                            ]);
                        }

                        try {
                            for (const index in data.facets) {
                                const facet = data.facets[index];
                                if (
                                    facet.name == "Allergens" ||
                                    facet.name == "allergens"
                                ) {
                                    console.log(facet);
                                    if (
                                        parseInt(facet.facetValues.lupiner) == 1
                                    ) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "lupin",
                                        ]);
                                    }
                                    if (facet.facetValues.selleri == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "celery",
                                        ]);
                                    }
                                    if (
                                        parseInt(facet.facetValues.sennep) == 1
                                    ) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "mustard",
                                        ]);
                                    }
                                    if (facet.facetValues.gluten == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "gluten",
                                        ]);
                                    }
                                    if (facet.facetValues.fisk == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "fish",
                                        ]);
                                    }
                                    if (facet.facetValues.soya == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "soy",
                                        ]);
                                    }
                                    if (facet.facetValues.sesam == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "sesame",
                                        ]);
                                    }
                                    if (facet.facetValues.sulfitt == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "sulphites",
                                        ]);
                                    }
                                    if (facet.facetValues.notter == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "treeNuts",
                                        ]);
                                    }
                                    if (facet.facetValues.peanotter == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "peanuts",
                                        ]);
                                    }
                                    if (facet.facetValues.egg == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "eggs",
                                        ]);
                                    }
                                    if (facet.facetValues.blotdyr == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "molluscs",
                                        ]);
                                    }
                                    if (facet.facetValues.skalldyr == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "shellfish",
                                        ]);
                                    }
                                    if (facet.facetValues.melk == 1) {
                                        setAllergens((allergens) => [
                                            ...allergens,
                                            "milk",
                                        ]);
                                    }
                                }
                            }
                        } catch (e) {
                            setErrorMessages((errorMessages) => [
                                ...errorMessages,
                                "Matvarens allergener ble ikke funnet",
                            ]);
                        }
                    } catch (e) {
                        setErrorMessages((errorMessages) => [
                            ...errorMessages,
                            "Matvaren ble ikke funnet hos Meny",
                        ]);
                    }
                }
            } catch (e) {
                setErrorMessages((errorMessages) => [
                    ...errorMessages,
                    "Matvaren ble ikke funnet hos Meny",
                ]);
            }
        }
    };

    return (
        <>
            <div className="p-4 box text-center overflow-auto position-relative">
                <Link to="/home">
                    <Button
                        className="position-absolute start-0 top-0 m-3"
                        variant="light"
                    >
                        &lt;
                    </Button>
                </Link>
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/askvikdevelopmentwebsite.appspot.com/o/foodwinlogo512.png?alt=media&token=58a4b6d6-41f8-4dda-a8f5-36f454e3097d"
                    alt="Logo"
                    id="logo"
                    onClick={(e) => {
                        if (displayNutrients === false) {
                            setDisplayNutrients(true);
                        } else {
                            setDisplayNutrients(false);
                        }
                    }}
                />
                <h2 className="mb-3">Legg til matvare</h2>
                <hr />
                <div className="mb-3">
                    {successMessages.map((successMessage) => (
                        <Alert
                            key={successMessage}
                            className="end-0"
                            name={successMessage}
                            variant="success"
                        >
                            {successMessage}
                        </Alert>
                    ))}
                </div>
                <div className="mb-3">
                    {errorMessages.map((errorMessage) => (
                        <Alert
                            key={errorMessage}
                            className="end-0"
                            name={errorMessage}
                            variant="danger"
                        >
                            {errorMessage}
                        </Alert>
                    ))}
                </div>
                <Form onSubmit={getFoodFromNgData}>
                    <Form.Group className="mb-3" controlId="brand">
                        <Form.Label>Søk etter matvare med gtin:</Form.Label>
                        <Form.Control
                            id="searchGtin"
                            type="text"
                            onChange={(e) => setGtin(parseInt(e.target.value))}
                        />
                    </Form.Group>
                    <Button className="w-100" variant="primary" type="submit">
                        Søk
                    </Button>
                </Form>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="brand">
                        <Form.Label>Merke:</Form.Label>
                        <Form.Control
                            value={brand}
                            type="text"
                            onChange={(e) =>
                                setBrand(e.target.value.toLowerCase())
                            }
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="foodItem">
                        <Form.Label>Matvare:</Form.Label>
                        <Form.Control
                            value={food}
                            type="text"
                            onChange={(e) =>
                                setFood(e.target.value.toLowerCase())
                            }
                        />
                    </Form.Group>

                    <div className="container">
                        <div className="row">
                            <Form.Group
                                className="mb-3 col-3 p-0"
                                controlId="amount"
                            >
                                <Form.Label>Mengde:</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(e) => {
                                        if (e.target.value.includes(",")) {
                                            setAmount(
                                                parseFloat(
                                                    e.target.value.replace(
                                                        ",",
                                                        "."
                                                    )
                                                )
                                            );
                                        } else {
                                            setAmount(
                                                parseFloat(e.target.value)
                                            );
                                        }
                                    }}
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3 col-9 p-0"
                                controlId="unitOfMeasurement"
                            >
                                <Form.Label>Enhet:</Form.Label>
                                <Form.Select
                                    value={unitOfMeasurement}
                                    onChange={(e) =>
                                        setUnitOfMeasurement(e.target.value)
                                    }
                                >
                                    <option value="stk">stk</option>
                                    <option value="mg">mg (milligram)</option>
                                    <option value="g">g (gram)</option>
                                    <option value="kg">kg (kilogram)</option>
                                    <option value="ml">ml (milliliter)</option>
                                    <option value="dl">dl (desiliter)</option>
                                    <option value="l">l (liter)</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>

                    <Form.Group className="mb-3" controlId="category">
                        <Form.Label>Kategori:</Form.Label>
                        <Form.Select
                            onChange={(e) =>
                                setCategory(parseInt(e.target.value))
                            }
                            defaultValue="1"
                        >
                            <option value="1">
                                1 - Melk og melkeprodukter
                            </option>
                            <option value="2">2 - Egg</option>
                            <option value="3">3 - Fjørfe og kjøtt</option>
                            <option value="4">4 - Fisk og skalldyr</option>
                            <option value="5">
                                5 - Korn- og bakevarer, frø og nøtter
                            </option>
                            <option value="6">6 - Grønnsaker</option>
                            <option value="7">
                                7 - Sukker og søte produkter
                            </option>
                            <option value="8">
                                8 - Margarin, smør, matolje o.l.
                            </option>
                            <option value="9">9 - Drikke</option>
                            <option value="10">
                                10 - Diverse retter, produkter og ingredienser
                            </option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="countryOfOrigin">
                        <Form.Label>Opprinnelsesland:</Form.Label>
                        <Form.Control
                            value={countryOfOrigin}
                            type="text"
                            onChange={(e) =>
                                setCountryOfOrigin(e.target.value.toLowerCase())
                            }
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="gtin">
                        <Form.Label>GTIN (strekkode):</Form.Label>
                        <Form.Control
                            placeholder={gtin.toString()}
                            type="text"
                            onChange={(e) => {
                                setGtin(parseInt(e.target.value));
                            }}
                        />
                    </Form.Group>

                    {displayNutrients && (
                        <div>
                            <h2 className="mb-3 p-3">
                                Næringsinnhold per 100g
                            </h2>
                            <hr />
                            <Form.Group className="mb-3" controlId="energy">
                                <Form.Label>Energi (kJ):</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled
                                    onChange={(e) =>
                                        setEnergy(parseFloat(e.target.value))
                                    }
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="calories">
                                <Form.Label>Kalorier (kcal):</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled
                                    onChange={(e) =>
                                        setCalories(parseFloat(e.target.value))
                                    }
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="fat">
                                <Form.Label>Fett:</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled
                                    onChange={(e) =>
                                        setFat(parseFloat(e.target.value))
                                    }
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="saturatedFat"
                            >
                                <Form.Label>Mettet fett:</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled
                                    onChange={(e) =>
                                        setSaturatedFat(
                                            parseFloat(e.target.value)
                                        )
                                    }
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="carbohydrates"
                            >
                                <Form.Label>Karbohydrater:</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled
                                    onChange={(e) =>
                                        setCarbohydrates(
                                            parseFloat(e.target.value)
                                        )
                                    }
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="sugars">
                                <Form.Label>Sukkerarter:</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled
                                    onChange={(e) =>
                                        setSugars(parseFloat(e.target.value))
                                    }
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="dietaryFibers"
                            >
                                <Form.Label>
                                    Kostfiber (kan sløyfes):
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled
                                    onChange={(e) =>
                                        setDietaryFiber(
                                            parseFloat(e.target.value)
                                        )
                                    }
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="protein">
                                <Form.Label>Protein:</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled
                                    onChange={(e) =>
                                        setProtein(parseFloat(e.target.value))
                                    }
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="salt">
                                <Form.Label>Salt:</Form.Label>
                                <Form.Control
                                    type="text"
                                    disabled
                                    onChange={(e) =>
                                        setSalt(parseFloat(e.target.value))
                                    }
                                />
                            </Form.Group>

                            <h2 className="mb-3 p-3">Ingredienser</h2>

                            <Form.Group className="mb-3">
                                <Form.Control
                                    className="mb-3"
                                    type="text"
                                    id="ingredientInput"
                                    disabled
                                />
                                <Button
                                    variant="light"
                                    disabled
                                    className="mb-3 col-12 border border-dark"
                                    onClick={() => {
                                        if (
                                            ingredients.indexOf(
                                                document
                                                    .getElementById(
                                                        "ingredientInput"
                                                    )
                                                    .value.toLowerCase()
                                            ) > -1
                                        ) {
                                            alert(
                                                document
                                                    .getElementById(
                                                        "ingredientInput"
                                                    )
                                                    .value.toLowerCase() +
                                                    " er allerede lagt til!"
                                            );
                                        } else {
                                            setIngredients([
                                                ...ingredients,
                                                document
                                                    .getElementById(
                                                        "ingredientInput"
                                                    )
                                                    .value.toLowerCase(),
                                            ]);
                                            document.getElementById(
                                                "ingredientInput"
                                            ).value = "";
                                        }
                                    }}
                                >
                                    Legg til ingrediens
                                </Button>
                            </Form.Group>

                            <div className="mb-3">
                                {ingredients.map((ingredient) => (
                                    <div key={ingredient}>
                                        <Button
                                            name={ingredient}
                                            variant="danger"
                                            onClick={handleRemoveIngredient}
                                        >
                                            Fjern {ingredient}
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <h2 className="mb-3 p-3">Allergener</h2>
                    <Form.Group className="mb-3">
                        <Form.Select
                            className="mb-3"
                            id="allergenForm"
                            defaultValue="gluten"
                        >
                            <option value="gluten">Gluten</option>
                            <option value="shellfish">Skalldyr</option>
                            <option value="eggs">Egg</option>
                            <option value="fish">Fisk</option>
                            <option value="peanuts">Peanøtter</option>
                            <option value="soy">Soya</option>
                            <option value="milk">Melk</option>
                            <option value="treeNuts">Andre nøtter</option>
                            <option value="celery">Selleri</option>
                            <option value="mustard">Sennep</option>
                            <option value="sesame">Sesamfrø</option>
                            <option value="sulphites">
                                Svoveldioksid og sulfitter
                            </option>
                            <option value="lupin">Lupin</option>
                            <option value="molluscs">Bløtdyr</option>
                        </Form.Select>
                        <Button
                            variant="light"
                            className="mb-3 col-12 border border-dark"
                            onClick={() => {
                                if (
                                    allergens.indexOf(
                                        document.getElementById("allergenForm")
                                            .value
                                    ) > -1
                                ) {
                                    alert(
                                        document.getElementById("allergenForm")
                                            .value + " er allerede lagt til!"
                                    );
                                } else {
                                    setAllergens((allergens) => [
                                        ...allergens,
                                        document.getElementById("allergenForm")
                                            .value,
                                    ]);
                                }
                            }}
                        >
                            Legg til allergen
                        </Button>
                        <Button
                            variant="danger"
                            className="mb-3 col-12 border border-dark"
                            onClick={() => {
                                setAllergens([]);
                            }}
                        >
                            Fjern alle allergener
                        </Button>
                    </Form.Group>

                    <div className="mb-3">
                        {allergens.map((allergen) => (
                            <div key={allergen}>
                                <Button
                                    className="end-0"
                                    name={allergen}
                                    variant="danger"
                                    onClick={handleRemoveAllergen}
                                >
                                    Fjern {allergen}
                                </Button>
                            </div>
                        ))}
                    </div>

                    <h2 className="mb-3 p-3">Butikker</h2>
                    <Form.Group className="mb-3">
                        <Form.Select
                            className="mb-3"
                            id="chainForm"
                            defaultValue="meny"
                        >
                            <option value="kiwi">Kiwi</option>
                            <option value="meny">Meny</option>
                            <option value="joker">Joker</option>
                            <option value="bunnpris">Bunnpris</option>
                            <option value="spar">Spar</option>
                            <option value="rema">Rema</option>
                            <option value="obs">Coop Obs</option>
                            <option value="extra">Coop Extra</option>
                        </Form.Select>
                        <Button
                            variant="light"
                            className="mb-3 col-12 border border-dark"
                            onClick={() => {
                                if (
                                    chains.indexOf(
                                        document.getElementById("chainForm")
                                            .value
                                    ) > -1
                                ) {
                                    alert(
                                        document.getElementById("chainForm")
                                            .value + " er allerede lagt til!"
                                    );
                                } else {
                                    setChains((chains) => [
                                        ...chains,
                                        document.getElementById("chainForm")
                                            .value,
                                    ]);
                                }
                            }}
                        >
                            Legg til butikk
                        </Button>
                        <Button
                            variant="danger"
                            className="mb-3 col-12 border border-dark"
                            onClick={() => {
                                setChains([]);
                            }}
                        >
                            Fjern alle butikker
                        </Button>
                    </Form.Group>

                    <div className="mb-3">
                        {chains.map((chain) => (
                            <div key={chain}>
                                <Button
                                    className="end-0"
                                    name={chain}
                                    variant="danger"
                                    onClick={handleRemoveChain}
                                >
                                    Fjern {chain}
                                </Button>
                            </div>
                        ))}
                    </div>

                    <hr />
                    <Button className="w-100" variant="primary" type="submit">
                        Legg til matvare
                    </Button>
                </Form>
            </div>
        </>
    );
};

export default AddFood;
