import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCXeCd3VI5Z_NeX8VBKheQKAXPBKrgoaBU",
    authDomain: "foodwin-67271.firebaseapp.com",
    projectId: "foodwin-67271",
    storageBucket: "foodwin-67271.appspot.com",
    messagingSenderId: "774625364513",
    appId: "1:774625364513:web:4eb99e2e6109ecb762f160",
    measurementId: "G-CTFWK36Z5E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;
