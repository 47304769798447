import React from "react";
import { useState } from "react";
import FoodDataService from "../services/foods.services";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import { db } from "../firebase";

import { query, collection, getDocs, where, getDoc, limit, onSnapshot } from "firebase/firestore";
import AddFoodComponent from "./AddFoodComponent";

const AskoToFoodWin = ({ addFood }) => {
    const [productsFromAsko, setProductsFromAsko] = useState([]);
    const [foods, setFoods] = useState([]);

    const [first, setFirst] = useState(1);
    const [last, setLast] = useState(20);

    const [askoCategory, setAskoCategory] = useState("")

    const [errorMessages, setErrorMessages] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);

    const getFoodFromAsko = async (e) => {
        let allProducts = []
        e.preventDefault();
        const getRequestUrl = () => {
            if (askoCategory == "") {
                return `https://askoservering.no/api/search/searchhits?first=${first}&last=${last}&sortby=varenavn+asc`
            } else {
                return `https://askoservering.no/api/search?c=${askoCategory}&first=${first}&last=${last}&sortby=varenavn+asc`
            }
        }
        let response = await fetch(getRequestUrl());
        let data = await response.json();
        try {
            if (typeof data.searchHits == "object") {
                try {
                    for (let index = 0; index < data.productLines.length; index++) {
                        try {
                            const product = data.productLines[index].product;
                            let detailsResponse = await fetch(
                                `https://askoservering.no/api/product-page/${product.epdNumber}`
                            );
                            let detailedData = await detailsResponse.json();
                            let detailedProduct = detailedData.product
                            let gtinFPak = detailedProduct.gtinFpak
                            if(typeof gtinFPak === "undefined") {
                                throw new Error('gtinFpak is undefined');
                            }
                            allProducts.push(detailedProduct)
                            getFood(gtinFPak, index)
                        } catch (e) {
                            console.log(e)
                        }
                    }
                } catch (e) {
                    console.log(e)
                }
            }
        } catch (e) {
            console.log(e)
        }
        setProductsFromAsko(allProducts)
        console.log(allProducts)
    };

    Number.prototype.asFormattedCategory = function asFormattedCategory() {
        switch (this) {
            case 1: return "Melk og melkeprodukter";
            case 2: return "Egg";
            case 3: return "Fjørfe og kjøtt";
            case 4: return "Fisk og skalldyr";
            case 5: return "Korn- og bakevarer, frø og nøtter";
            case 6: return "Grønnsaker";
            case 7: return "Sukker og søte produkter";
            case 8: return "Margarin, smør, matolje o.l.";
            case 9: return "Drikke";
            case 10: return "Diverse retter, produkter og ingredienser";
            default: return "No category";
        };
    };

    const getFood = (itemGtin, index) => {
        const q = query(collection(db, "foods"), where("gtin", "==", Number(itemGtin)), limit(1));
        onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setFoods((foods) => [...foods.filter (food =>
                    food.gtin !== Number(itemGtin)
                ), doc.data()]);
            });
        });
    };

    return (
        <>
            <div className="p-4 text-center overflow-auto position-relative">
                <h2 className="mb-3">Asko {"<"}--{">"} FoodWin</h2>
                <hr />
                <Row className="mb-0">
                        <Col>
                            <Form.Group className="mb-3" controlId="askoCategory">
                                <Form.Label>Asko kategori:</Form.Label>
                                <Form.Control
                                    value={askoCategory}
                                    type="text"
                                    onChange={(e) =>
                                        setAskoCategory(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="brand">
                                <Form.Label>Første indeks:</Form.Label>
                                <Form.Control
                                    value={first}
                                    type="number"
                                    onChange={(e) =>
                                        setFirst(e.target.value.toLowerCase())
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="foodItem">
                                <Form.Label>Siste indeks:</Form.Label>
                                <Form.Control
                                    value={last}
                                    type="number"
                                    onChange={(e) =>
                                        setLast(e.target.value.toLowerCase())
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                <Button
                    name="Hent varer fra Asko"
                    onClick={getFoodFromAsko}
                >
                    Hent varer fra Asko
                </Button>
           
                {productsFromAsko.map((product, index) => {
                    const food = foods.find((food) => food.gtin === Number(product.gtinFpak))
                    return (
                        <Card className="p-3">
                            <div>
                                <h4 className="mb-0">{product.gtinFpak}</h4>
                                <hr />
                                <Image src={product.imageUrls[0]} rounded className="imageSize" />
                            </div>
                            <Row key={product.gtinFpak} className="mb-0">
                                <Col className="p-0">
                                    <p>
                                        Asko
                                    </p>
                                    <p className="textInList boldText">
                                        {product.navn}
                                    </p>
                                    <p className="textInList">
                                        {product.opprinnelse.produksjonsland.name}
                                    </p>
                                </Col>
                                <Col xs={1} className="mb-0 p-0">
                                    <div className="divider"></div>
                                </Col>
                                <Col className="p-0">
                                    <p>
                                        FoodWin
                                    </p>
                                    {typeof food != "undefined" ? (
                                        <>
                                            <p className="textInList boldText">
                                                {food.food.charAt(0).toUpperCase() + food.food.slice(1)}
                                            </p>
                                            <p className="textInList">
                                                {food.amount} {food.unitOfMeasurement} • {food.brand.charAt(0).toUpperCase() + food.brand.slice(1)}
                                            </p>
                                            <p className="textInList">
                                                {food.countryOfOrigin.charAt(0).toUpperCase() + food.countryOfOrigin.slice(1)}
                                            </p>
                                            <p className="textInList">
                                                {food.category.asFormattedCategory()}
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                name="Legg til vare"
                                                onClick={() => addFood({
                                                    brand: product.leverandorinfo.leverandornavn,
                                                    food: product.navn,
                                                    gtin: product.gtinFpak,
                                                    countryOfOrigin: product.opprinnelse.produksjonsland.name,
                                                    amount: product.packagingInfo.fPakSize.split(' ')[0],
                                                    unitOfMeasurement: product.packagingInfo.fPakSize.split(' ')[1],
                                                })}
                                            >
                                                Legg til vare
                                            </Button>
                                        </>

                                    )}
                                </Col>
                            </Row>
                        </Card>
                    );
                })}
            </div>
        </>
    );
};

export default AskoToFoodWin;